const drivers = [
    { student_id: 20068200, name: "Ozzy Domarkas", time: 22.446 },
    { student_id: 20093532, name: "Lorna O'Dwyer", time: 22.094 },
    { student_id: 20106584, name: "Jamie Doyle", time: 22.121 },
    { student_id: 10000003, name: "Luka Krezo", time: 22.547 },
    { student_id: 20106593, name: "Dylan Rockett", time: 23.057 },
    { student_id: 20107376, name: "Atila Astalos", time: 22.99 },
    { student_id: 10000004, name: "Nicholas", time: 25.072 },
];

export default drivers;
